#rootHome{
  background-color: #F8F8F8;
}
.div-btn-role {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.container-btn-role {
  width: 39.375%;
  display: flex;
}
.div-choix-jesuis {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54.2%;
  color: white;
}
.btn-role:not(:last-of-type) {
  margin-right: 3.9%;
}
.btn-role {
  // width: 10.68%;
  cursor: pointer;
}

//.btn-role habiller {
//  width: 100%;
//}

.btn-role {
  // width: 30.68%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 18em;
}

.btn-role svg {
  width: 100%;
}
.btnRolesimg{
  stroke-width: 5px;
  // width: 18em;
  padding-inline: 2em;
  justify-content: center;
  min-width: 10em;
  max-width: 30vw;
}
.btnRolesCompany{
  width: 70%;
  fill: #618226;
  stroke: #fff;
  stroke-width: 4px;
  // margin-right: 6.9%;
  align-self: center;

}

@media screen and (max-width: 480px) {
  .btn-role {
    display: block;
    margin-top: 9em;
    margin-left: 6vw;    
  }

  .btnRolesCompany img {
    margin-bottom: 22%;
  }

  .btnRolesimg {
    min-width: 12em;
  }
}