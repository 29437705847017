
//$width_screen: 1920px;
.cards-row{
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  padding-top: 100px;
  justify-content: flex-start;
}

.article_c{
  padding-inline: 2%;
}
.card_category{

}
.atag{
  text-decoration-line: unset;
}
.atag:hover{
  text-decoration-line: unset;
}

// .card_mrgb{
//   width: 22%;
// }
.div-cards {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6.25% 0% 0% 0%;
  background-color: #F8F8F8;
}