@import "../../../styles/function";
.repertory-description{
}
.repertory-content {
  $width_responsible_content: 1413px;
  $width_screen: 1920px;


  .title {
    h1 {
      //padding-right: pxToPourcent(-317px,$width_screen);
      font-size: pxToVw(44px,$width_screen);
      font-weight: bold;
    }

  }

  .title-content {
    padding-top: pxToPourcent(41px,$width_screen);
    font-size: pxToVw(20px,$width_screen);
  }

  .responsible-production {
    margin-bottom: pxToPourcent(220,$width_screen);
    margin-top:  pxToPourcent(66,$width_screen);
    position: relative;
    text-align: left;
    justify-content: space-around;
    background-color: white;

    h3 {
      &.l-green {
        font-size: pxToVw(44px,$width_screen);
        padding-top: pxToPourcent(150px,$width_screen);
        padding-right: pxToPourcent(75,$width_screen);
      }
    }

    .logo-eye {
      //padding-top: pxToPourcent(110px,$width_screen);
      //height: 80%;
      padding-left: 20px;
      margin-right: 24px;
    }

    .responsible-title{
      width: 70%;
      padding-left: pxToPourcent(100,$width_screen);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .responsible-content-1 {
      font-size: pxToVw(20px,$width_screen);
      padding-top: pxToPourcent(89px,$width_screen);
      padding-left: pxToPourcent(100,$width_screen);
      padding-right: pxToPourcent(196,$width_screen);
    }

  }

  .responsible-content-2 {
    font-size: pxToVw(20px,$width_screen);
    padding-top: pxToPourcent(24.59px,$width_screen);
    padding-left: pxToPourcent(100,$width_screen);
    padding-bottom: pxToPourcent(119,$width_screen);
    padding-right: pxToPourcent(397,$width_screen);
  }

}