@import "../../../styles/function";

$width_screen: 1920px;
#footerid {
  background-image: url("../../../../public/css/img/footer/footer.png");
  background-position:center;
  background-size: cover ;
  background-repeat: no-repeat;

}
.activities-sector{
  background-color: #F8F8F8;
}

.search-product-button {
  width: pxToPourcent(400px,$width_screen);
  background: none;
  //margin-right: pxToPourcent(190px,$width_screen);
}
.centerElem{
  justify-content: center;
  margin: auto;
  width: 100%;
  font-size: 50px;
  text-align: center;
}
footer{
  position: relative;
  color: white;
  width: 100%;
  font-size: 1.04vw;
}

.image1{
  background-image: url("../../../../public/css/icon/facebookb.png");
}
.image2{
  background-image: url("../../../../public/css/icon/linkedinb.png");
}
.image3{
  background-image: url("../../../../public/css/icon/twitterb.png");
}

footer a,footer ul li,footer ul{
  color: white;
  text-decoration: none!important;
  list-style: none ;
  padding : 0 ;
}
.div-container-data-footer{
  padding-top: 3.5%;
}

footer a:hover{
  color: red;
  text-decoration: none!important;
}

footer .title-footer{
  margin-bottom: 5%;
  font-size: 1.25vw ;
  font-weight: bold;
}
footer .title-footer,footer ul{
  text-align : left ;
}

ul li,ul,li {
  text-decoration: none!important;
}
.container-data-footer{
  display : flex ;
  justify-content: center;
}
.footer-colonne:not(:last-of-type){
  margin-right: 21%;
}
.container-icones-footer{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.icon-footer{
  width:3%;
  margin-bottom: 2.34375%;
}
.icon-footer:not(:last-of-type)
{

  margin-right:3.44%;
}

@media screen and (max-width: 1024px) and (min-height: 620px) {

  footer{
    font-size: 2.5vw;

    .title-footer{
      font-size: 3vw;
    }
    .div-container-data-footer {
      padding-left: 8.5%;
      padding-right: 8.5%;
    }
    .icon-footer {
      width: 10%;
    }
  }

}

@media screen and (max-width: 480px){
  footer{
    font-size: 2.5vw;

    .title-footer{
      font-size: 3vw;
    }
    .div-container-data-footer {
      padding-left: 8.5%;
      padding-right: 8.5%;
    }

    .icon-footer {
      width: 8%;
      img{
        width: 100%;
      }
    }
  }

}
.activities-sector{
  background-color: #F8F8F8;
}