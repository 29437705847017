@use "../../../styles/function";

.divPartenaires {
  background-image: url("/public/images/quisommesnous/background partenaires.png");
  background-size: 100% function.pxToVw(847px, 1920px);
  background-repeat: no-repeat;
}
.partenairsTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 1.98vw;
  font-weight: bold;
  margin-top: function.pxToVw(93.02px, 1920px);
  margin-bottom: 2.6%;
  padding-top: 3em;
}
.four-partnairs{
  display: flex;
  flex-direction: row;
  width: function.pxToVw(1600px, 1920px);
  margin: auto;
}
.partnairs-div{
  margin:  auto ;
  width: function.pxToVw(300px,1920px);
  justify-content: center;
  text-align: center;
}
.four-partnairs :nth-child(2) .logoIcon img{
  margin-top: 2vh;
}
.logoIcon{
  width: function.pxToVw(301px,1920px);
  height: 90px;
  margin: function.pxToVw(48px,1920px) 0;


}
.text-partnairs{
  font-size: function.pxToVw(20px,1920px);
  font-family: Droid Sans, Regular;
  margin: auto;
  width: 15.625vw;
  height: 100%;
  color: #FFFFFF;
  line-height: 1.25vw;
  padding-top: function.pxToVw(54px,1920px);

}
.namePartnair{
  color: #e0ddc9;
  font-size: function.pxToVw(24px,1920px);
  font-weight: bold;
  margin: 0;

}
.founderPartenaire{
  color: #e0ddc9;
  font-weight: bold;
  margin: 0;
  text-decoration: underline;
  font-size: function.pxToVw(22px,1920px);
  margin-bottom: function.pxToVw(100px,1920px);
}
.logoEcoMinds{
  width: function.pxToVw(309px,1920px);
  height: function.pxToVw(138px,1920px);

}
.logo_slp{
  width: function.pxToVw(211px,1920px);
  height: function.pxToVw(150px,1920px);
  margin-top: function.pxToVw(5px,1920px);

}
.logoGreenCoaching{
  width: function.pxToVw(314px,1920px);
  height: function.pxToVw(101px,1920px);
  margin-top: function.pxToVw(35px,1920px);
}